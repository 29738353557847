<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="12" md="3">
        <v-btn rounded color="accent" class="black--text" dark @click="backToBoard">Back to board</v-btn>
        <v-card v-if="isAdmin" color="darkblue" elevation="0" class="mt-10 mr-5">
          <v-card-title> Settings (Admin)</v-card-title>
          <v-select
            outlined
            dense
            color="accent"
            label="Status"
            v-model="request.status"
            :items="statuses"
            item-color="accent"
            @change="changeStatus"
          ></v-select>
          <v-btn rounded outlined small color="danger" @click="deleteRequest">Delete Request</v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" md="7" v-if="!loading">
        <PostCard v-if="!loading" :initialCardData="request" :boardId="activeBoard.id" />
        <v-textarea class="mt-4" outlined rows="1" label="Add a comment" color="accent" auto-grow v-model="newComment"></v-textarea>
        <v-btn v-if="canComment" rounded color="accent" class="black--text mb-10" dark @click="addComment">Add comment</v-btn>
        <p class="text-h5 text-secondary" v-if="comments.length">Activity</p>
        <CommentCard v-for="comment in comments" :key="comment.id" :comment="comment" class="mb-5"></CommentCard>
      </v-col>
      <v-col cols="12" md="2"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import PostCard from "./PostCard.vue";
import CommentCard from "./CommentCard.vue";
import { isDomainAdmin } from "../services/auth";
import { updateRequest, getRequest, deleteRequest, addComment } from "../services/database";

const db = firebase.firestore();
export default {
  props: ["boardId"],
  components: {
    PostCard,
    CommentCard,
  },
  data: () => ({
    authUser: null,
    title: "",
    activeBoard: { name: "", id: "" },
    comments: [],
    newComment: "",
    request: {
      id: null,
      name: "",
      votes: 0,
      isUpvoted: false,
      status: "",
      description: "",
      avatar: "",
      author: "Joe Schmoe",
    },
    loading: true,
    statuses: ["Not Started", "In Progress", "Done", "Planned", "Closed"],
    isAdmin: false,
  }),
  methods: {
    loadBoards() {
      db.collection("boards")
        .where("domain", "==", this.$route.params.name)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let board = doc.data();
            if (board.name === this.$route.params.board) {
              this.activeBoard.name = board.name;
              this.activeBoard.id = doc.id;
              this.loadRequest();
            }
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    backToBoard() {
      this.$router.push({ name: "Board", params: { name: this.$route.params.name } });
    },
    async loadRequest() {
      this.request = await getRequest(this.$route.params.name, this.$route.params.board, this.$route.params.title);
      this.loading = false;
      this.loadComments();
    },
    async deleteRequest() {
      await deleteRequest(this.request.id);
      this.$router.push({
        path: "/" + this.$route.params.name + "/board/" + this.$route.params.board,
      });
    },
    loadComments() {
      this.comments = [];
      db.collection("comments")
        .where("domain", "==", this.$route.params.name)
        .where("request_id", "==", this.request.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let comment = doc.data();
            this.comments.push(comment);
          });
          this.sortComments();
        });
    },
    async sortComments() {
      this.comments.sort((a, b) => {
        return a.date.seconds - b.date.seconds;
      });
    },
    async addComment() {
      if (!this.authUser) {
        this.$router.push({ name: "Login" });
      }
      const response = await addComment({
        domain: this.$route.params.name,
        board_id: this.activeBoard.id,
        request_id: this.request.id,
        author: this.authUser.displayName,
        author_id: this.authUser.uid,
        content: this.newComment,
        date: new firebase.firestore.Timestamp.now(),
      });
      if (response) {
        this.newComment = "";
        this.loadComments();
      } else {
        alert("Error adding comment");
      }
    },
    async changeStatus() {
      await updateRequest(this.request.id, {
        status: this.request.status,
      });
    },
  },
  mounted: function () {
    this.title = this.$route.params.title;
    this.loadBoards();
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.authUser = user;
        this.isAdmin = await isDomainAdmin(this.authUser.uid, this.$route.params.name);
      } else {
        this.authUser = null;
      }
    });
  },
  computed: {
    canComment() {
      return this.newComment.length > 0;
    },
  },
};
</script>

<style></style>
