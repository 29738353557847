<template>
  <div>
    <v-sheet :color="color" rounded outlined class="my-2 mr-5">
      <v-card rounded color="#1d262b" dark elevation="0" style="min-width: 0" @click="openCard()">
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="py-2">
              <span class="text-h6 one-line">{{ initialCardData.name }}</span>
            </v-card-title>
            <v-card-text class="text--primary pb-3">
              {{ initialCardData.board }}
            </v-card-text>
          </div>
          <!-- <div class="text-h4 mr-5" style="display: flex; align-items: center">{{ voteCount }}</div> -->
        </div>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>
import { sum, values } from "lodash";
export default {
  props: ["initialCardData", "color"],
  computed: {
    voteCount() {
      return sum(values(this.initialCardData.voters));
    },
  },
  methods: {
    openCard() {
      this.$router.push({
        path: "/" + this.$route.params.name + "/board/" + this.initialCardData.board + "/request/" + this.initialCardData.name,
        params: { title: this.initialCardData.name },
        props: { boardId: null },
      });
    },
  },
};
</script>

<style>
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  word-break: break-word;
}
</style>
