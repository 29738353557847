<template>
  <div>
    <v-card @click="openCard()" class="mx-auto rounded-xl" color="#1d262b" dark elevation="0">
      <v-card-title class="pb-0 pl-0 pt-2">
        <!-- <v-list-item-avatar color="grey darken-3">
          <avatar :username="cardData.author"></avatar>
        </v-list-item-avatar> -->
        <span class="text-h6 font-weight-bold">{{ cardData.name }}</span>
      </v-card-title>

      <v-card-text class="text-body2 pb-0" v-if="parsedDescription.length < 300">
        {{ parsedDescription }}
      </v-card-text>
      <v-card-text class="text-body2 pb-0" v-else>
        {{ parsedDescription }}
      </v-card-text>

      <v-card-actions class="pb-1 pt-0">
        <v-list-item class="grow">
          <v-row align="center" justify="start">
            <span class="text-h5">{{ voteCount }}</span>
            <v-btn icon @click.stop="toggleIsUpvoted()">
              <v-icon v-if="isUpvoted" color="accent">mdi-arrow-up-bold</v-icon>
              <v-icon v-else>mdi-arrow-up-bold-outline</v-icon>
            </v-btn>

            <v-chip outlined class="ma-2 black--text" v-if="cardData.status === 'Not Started'" color="grey lighten-2">
              {{ cardData.status }}</v-chip
            >
            <v-chip outlined class="ma-2" v-if="cardData.status === 'In Progress'" color="orange lighten-3"> {{ cardData.status }} </v-chip>
            <v-chip outlined class="ma-2" v-if="cardData.status === 'Done'" color="green lighten-3"> {{ cardData.status }}</v-chip>
            <v-chip outlined class="ma-2" v-if="cardData.status === 'Planned'" color="cyan lighten-3"> {{ cardData.status }}</v-chip>
            <v-chip outlined class="ma-2" v-if="cardData.status === 'Closed'" color="red lighten-3"> {{ cardData.status }}</v-chip>
          </v-row>
          <v-spacer></v-spacer>
          <span class="text-h5 ml-1">{{ commentCount }}</span>
          <v-btn icon>
            <v-icon>mdi-comment-outline</v-icon>
          </v-btn>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// import Avatar from "vue-avatar";
import firebase from "firebase";
import { sum, values } from "lodash";
import { upvoteRequest } from "../services/database";

export default {
  // components: {
  //   Avatar,
  // },
  props: ["initialCardData", "boardId"],
  data: () => ({
    authUser: null,
    isUpvoted: false,
    voteCount: 0,
    votes: null,
    commentCount: 0,
    cardData: {
      id: null,
      name: "",
      votes: 0,
      status: "",
      description: "",
      avatar: "",
      author: "Joe Schmoe",
      voters: [],
    },
  }),
  methods: {
    async toggleIsUpvoted() {
      if (!this.authUser) {
        this.$router.push({ name: "Login" });
      }
      this.isUpvoted = !this.isUpvoted;
      if (this.isUpvoted) {
        await upvoteRequest(this.cardData.id, this.authUser.uid, 1);
        this.voteCount++;
      } else {
        await upvoteRequest(this.cardData.id, this.authUser.uid, 0);
        this.voteCount--;
      }
    },
    openCard() {
      this.$router.push({
        path: "/" + this.$route.params.name + "/board/" + this.cardData.board + "/request/" + this.cardData.name,
        params: { title: this.cardData.name },
        props: { boardId: this.boardId },
      });
    },
  },
  computed: {
    parsedDescription: function () {
      return this.cardData.description.replace(/<[^>]+>/g, "").replace(/&.*;/g, "");
    },
  },
  mounted: function () {
    this.cardData = this.initialCardData;

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.authUser = user;
      } else {
        this.authUser = null;
      }
      this.isUpvoted = this.authUser ? this.cardData.voters[this.authUser.uid] : false;
      this.voteCount = sum(values(this.cardData.voters));
      this.commentCount = this.cardData.comments.length;
    });
  },
};
</script>

<style></style>
