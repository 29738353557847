<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" :width="isMobile ? '90%' : '50%'">
    <v-sheet outlined color="accent" rounded>
      <v-card class="pa-2">
        <v-container>
          <v-card-title> <h3>Add new post</h3></v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form">
              <v-text-field color="accent" outlined label="Title" dense :rules="titleRules" v-model="title"></v-text-field>
              <VueEditor
                v-model="description"
                :editor-toolbar="customToolbar"
                placeholder="Description"
                :rules="descriptionRules"
              />
              <p v-if="errors.length" style="color: #ff5252;" class="mt-2">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </p>

              <v-file-input
                label="Attach images"
                v-model="images"
                dense
                prepend-icon="mdi-camera"
                small-chips
                accept="image/png, image/jpeg"
                color="accent"
                multiple
                :rules="imageRules"
                class="mt-8"
              ></v-file-input>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn rounded color="secondary" @click="cancel"> Cancel </v-btn>
            <v-btn rounded color="accent" class="black--text" @click="confirm"> Add post </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-sheet>
  </v-dialog>
</template>

<style>
.ql-editor.ql-blank::before {
  color: rgb(255, 255, 255, 0.5) !important;
  font-family: "Nunito" !important;
  font-style: normal !important;
}

.quillWrapper .ql-snow.ql-toolbar {
  border-radius: 5px 5px 0px 0px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0px 0px 5px 5px;
  font-family: "Nunito" !important;
}
</style>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "AddRequestDialog",
  components: {
    VueEditor,
  },
  data: () => ({
    title: "",
    description: "",
    customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block", "blockquote"]],
    valid: false,
    images: [],
    errors: [],
    titleRules: [
      (v) => !!v || "Title is required",
      (v) => !v.includes("/") || "Title cannot contain '/'",
      (v) => !v.includes("?") || "Title cannot contain '?'",
      (v) => (v && v.length >= 10) || "Title must be at least 10 characters",
    ],
    imageRules: [(v) => (v && v.length <= 5) || "You can upload up to 5 images"],
    descriptionRules: [(v) => !!v || "Description is required", (v) => (v && v.length >= 20) || "Description must be at least 20 characters"],
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.checkForm();
      if (this.$refs.form.validate() && this.errors.length === 0) {
        this.$emit("add-post", { title: this.title, description: this.description, images: this.images });
        this.title = "";
        this.description = "";
        this.images = [];
      }
    },
    checkForm() {
      this.errors = [];
      if (!this.description) {
        this.errors.push("Description is required.");
      }
      if (this.description?.length < 20) {
        this.errors.push("Description must be at least 20 characters.");
      }
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.width < 768;
    },
  },
};
</script>
