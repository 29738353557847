<template>
  <v-container>
    <h1 class="header">Login</h1>
    <p class="mb-10 mt-3">
      You must sign in before you can interact with boards and posts. You'll be redirected back to the previous page after logging in.
    </p>
    <v-btn color="accent" rounded class="black--text" @click="login">Sign in with Google</v-btn>
  </v-container>
</template>

<script>
import firebase from "firebase";

const googleAuth = new firebase.auth.GoogleAuthProvider();

export default {
  data: () => ({
    loggedIn: false,
  }),
  mounted: function () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
  },
  methods: {
    login() {
      if (!this.loggedIn) {
        firebase.auth().signInWithRedirect(googleAuth);
        firebase
          .auth()
          .getRedirectResult()
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
