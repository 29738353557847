<template>
  <v-container>
    <v-row class="my-5">
      <h1 v-if="displayName" class="mr-5">{{ displayName }} Changelog</h1>
      <v-img v-if="logoUrl" height="50" max-width="50" :src="logoUrl" style="align: center"></v-img>
    </v-row>
    <v-row v-if="isAdmin">
      <v-btn rounded color="accent" class="black--text" dark @click="openAddChangelogDialog"> Add new Changelog + </v-btn>
    </v-row>
    <v-row class="mt-10">
      <v-timeline dense clipped style="width: 100%">
        <v-timeline-item v-for="event in timelineEvents" :key="event.title" color="white" small class="mb-15">
          <v-row>
            <v-col cols="12" md="3">
              <h2>{{ event.title }}</h2>
              <p class="text--secondary">{{ event.date.toDateString() }}</p>
            </v-col>
            <v-col cols="12" md="1"> </v-col>
            <v-col>
              <p v-html="event.description" style="width: 100%"></p>
              <div v-if="event.images">
                <v-img
                  class="mr-2"
                  v-for="image in event.images"
                  :key="image"
                  :src="image"
                  max-width="200px"
                  max-height="200px"
                  style="display: inline-block; cursor: pointer"
                  @click="selectedItem = image"
                ></v-img>
              </div>
              <v-overlay v-if="selectedItem" @click="selectedItem = null">
                <v-img :src="selectedItem"></v-img>
              </v-overlay>
              <roadmap-card v-for="request in event.requests" :key="request.name" :initialCardData="getRequest(request)" color="" />
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-row>
    <AddChangelogDialog v-model="dialog" :requests="requests" @cancel="dialog = false" @add-post="addPost" />
  </v-container>
</template>

<script>
import firebase from "firebase";
import { getDomain, getRequestsPerDomain, addChangelog, getChangelogsPerDomain } from "../services/database";
import { isDomainAdmin } from "../services/auth";
import RoadmapCard from "./RoadmapCard.vue";
import AddChangelogDialog from "./AddChangelogDialog.vue";

let storageRef = firebase.storage().ref();

export default {
  components: {
    RoadmapCard,
    AddChangelogDialog,
  },
  data: () => ({
    authUser: null,
    requests: [],
    displayName: "",
    logoUrl: "",
    selectedItem: null,
    isAdmin: false,
    dialog: false,
    timelineEvents: [],
  }),
  methods: {
    async getDomainName() {
      let domain = await getDomain(this.$route.params.name);
      this.displayName = domain.displayname;
      this.logoUrl = domain.logoUrl;
    },
    getRequest(name) {
      return this.requests.find((r) => r.name == name);
    },
    async getRequests() {
      this.requests = await getRequestsPerDomain(this.$route.params.name);
    },
    async getChangelogs() {
      this.timelineEvents = await getChangelogsPerDomain(this.$route.params.name);
      for (let i = 0; i < this.timelineEvents.length; i++) {
        let year = this.timelineEvents[i].date.substring(0, 4);
        let month = this.timelineEvents[i].date.substring(5, 7);
        let day = this.timelineEvents[i].date.substring(8, 10);

        let date = new Date(year, month - 1, day);
        this.timelineEvents[i].date = date;
      }
      this.timelineEvents = this.timelineEvents.sort((a, b) => Date.parse(new Date(b.date)) - Date.parse(new Date(a.date))); // sort by date descending
    },
    openAddChangelogDialog() {
      this.dialog = true;
    },
    async addPost(post) {
      this.dialog = false;
      this.loading = true;
      let images = [];
      if (post.images) {
        for (let i = 0; i < post.images.length; i++) {
          let image = post.images[i];
          let imageRef = storageRef.child("images/" + "/" + this.authUser.uid + "/" + image.name);
          await imageRef.put(image);
          let imageUrl = await imageRef.getDownloadURL();
          images.push(imageUrl);
        }
      }
      const changelog = {
        title: post.title,
        description: post.description,
        date: post.date,
        images: images,
        requests: post.requests,
        domain: this.$route.params.name,
      };
      const response = await addChangelog(changelog);
      if (response && !response.error) {
        // fetch changelogs again
      } else {
        console.log("Error adding post");
        console.log(response);
      }
      this.loading = false;
    },
  },
  mounted: function () {
    console.log(new Date().toISOString().split("T")[0]);
    this.getDomainName();
    this.getRequests();
    this.getChangelogs();
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.authUser = user;
        this.isAdmin = await isDomainAdmin(this.authUser.uid, this.$route.params.name);
      } else {
        this.authUser = null;
      }
    });
  },
};
</script>

<style></style>
