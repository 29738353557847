<template>
  <v-container>
    <v-row class="my-5">
      <h1 v-if="displayName" class="mr-5">{{ displayName }} Roadmap</h1>
      <v-img v-if="logoUrl" height="50" max-width="50" :src="logoUrl" style="align: center"></v-img>
    </v-row>
    <v-row>
      <v-btn text color="accent" dark @click="goToBoards"><v-icon left> mdi-arrow-left </v-icon> View Boards </v-btn>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="4">
        <v-chip outlined class="my-2" color="cyan lighten-3">Planned</v-chip>
        <roadmap-card
          v-for="request in requests.filter((r) => r.status === 'Planned')"
          :key="request.name"
          :initialCardData="request"
          color="cyan lighten-3"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-chip outlined class="my-2" color="orange lighten-3">In Progress</v-chip>
        <roadmap-card
          v-for="request in requests.filter((r) => r.status === 'In Progress')"
          :key="request.name"
          :initialCardData="request"
          color="orange lighten-3"
      /></v-col>
      <v-col cols="12" md="4">
        <v-chip outlined class="my-2" color="green lighten-3">Done</v-chip>
        <roadmap-card
          v-for="request in requests.filter((r) => r.status === 'Done')"
          :key="request.name"
          :initialCardData="request"
          color="green lighten-3"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { getRequestsPerDomain, getDomain } from "../services/database";
import RoadmapCard from "./RoadmapCard.vue";

export default {
  name: "DomainHomePage",
  data: () => ({
    authUser: null,
    requests: [],
    displayName: "",
    logoUrl: "",
  }),
  components: {
    RoadmapCard,
  },
  methods: {
    async loadRequests() {
      const requests = await getRequestsPerDomain(this.$route.params.name);
      this.requests = requests;
    },
    async getDomainName() {
      let domain = await getDomain(this.$route.params.name);
      this.displayName = domain.displayname;
      this.logoUrl = domain.logoUrl;
    },
    goToBoards() {
      this.$router.push({
        path: "/" + this.$route.params.name + "/board",
      });
    },
  },
  mounted: function () {
    console.log(this.$route.params.name);
    this.getDomainName();
    this.loadRequests();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.authUser = user;
      } else {
        this.authUser = null;
      }
    });
  },
};
</script>

<style></style>
