<template>
  <v-container align="center" justify="center">
    <h1 class="text-center">Pricing</h1>
    <v-divider class="ma-5"></v-divider>
    <v-row class="mx-auto" style="width: 80%">
      <v-col v-for="product in products" v-bind:key="product.name" class="mx-5">
        <v-card elevation="0" color="darkblue">
          <v-card-title
            ><h3>{{ product.name }}</h3></v-card-title
          >
          <v-card-text class="pt-0"
            ><p>{{ product.description }}</p>
            <p>
              <span class="text-h3 font-weight-bold">${{ product.priceData.unit_amount / 100 }}</span>
              <span class="text-p">/ month</span>
            </p>
            <ul>
              <li v-for="feature in product.features" v-bind:key="feature">
                {{ feature }}
              </li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn
              style="width: 100%"
              class="black--text"
              color="accent"
              @click="subscribe(product.priceData.unit_amount / 100)"
              :disabled="loading"
              >Start Free Trial</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-auto mt-15" style="width: 80%"> Questions? Send us an email at help@loopliz.com </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";

export default {
  data: () => ({
    products: [],
    loading: false,
    loggedIn: false,
  }),
  mounted: async function () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
    let that = this;
    this.db = firebase.firestore();
    this.db
      .collection("products")
      .where("active", "==", true)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(async function (doc) {
          let newProduct = {};
          const product = doc.data();
          newProduct.name = product.name;
          newProduct.description = product.description;
          newProduct.features = product.feature_list;
          const priceSnap = await doc.ref.collection("prices").orderBy("unit_amount").get();
          newProduct.priceId = priceSnap.docs[0].id;
          newProduct.priceData = priceSnap.docs[0].data();
          that.products.push(newProduct);
        });
      });
  },
  methods: {
    subscribe(price) {
      this.loading = true;
      console.log(price);
      console.log(this.loggedIn);
      if (!this.loggedIn) {
        const googleAuth = new firebase.auth.GoogleAuthProvider();
        firebase
          .auth()
          .signInWithPopup(googleAuth)
          .then((result) => {
            console.log(result);
            this.$router.push({ name: "Checkout", params: { price: price } });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ name: "Checkout", params: { price: price } });
      }
    },
  },
  computed: {},
};
</script>

<style></style>
