import firebase from "firebase";
const db = firebase.firestore();

export async function isDomainAdmin(userId, domainName) {
  const domainId = await domainIdFromDomainName(domainName);
  return firebase
    .firestore()
    .collection("domains")
    .doc(domainId)
    .get()
    .then((doc) => {
      const domain = doc.data();
      return domain.admins.includes(userId);
    });
}

const domainIdFromDomainName = (domainName) => {
  return db
    .collection("domains")
    .where("name", "==", domainName)
    .get()
    .then((doc) => {
      const id = doc?.docs[0]?.id;
      if (!id) {
        throw new Error(`Domain with name ${domainName} does not exist`);
      }
      return id;
    });
};

export async function domainFromUser(userId) {
  return firebase
    .firestore()
    .collection("domains")
    .where("owner", "==", userId)
    .get()
    .then((doc) => {
      console.log(doc);
      if (doc.empty) {
        return null;
      } else {
        return doc.docs[0].data();
      }
    });
}
