<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="12" md="3"
        ><v-card elevation="0" rounded="20" outlined>
          <v-list color="darkblue">
            <v-list-item-group v-model="selectedItem" color="accent" mandatory>
              <v-list-item>
                <v-list-item-content> Workspace </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content> Boards </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content> Profile </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card></v-col
      >
      <v-col cols="12" md="7" v-if="selectedItem == 0 && plan">
        <div v-if="this.authUser && plan">
          <Workspace />
        </div>
        <div v-else>Not logged in</div>
      </v-col>
      <v-col cols="12" md="7" v-if="selectedItem == 1 && plan">
        <div v-if="this.authUser">
          <BoardGrid />
        </div>
        <div v-else>Not logged in</div>
      </v-col>
      <v-col cols="12" md="7" v-if="selectedItem == 2 || !plan">
        <div v-if="this.authUser">
          <v-container>
            <h1 class="mb-5">Account Information</h1>
            <!-- <img v-if="hasGoogleImage" :src="this.authUser.providerData[0].photoURL" referrerpolicy="no-referrer" style="border-radius: 50%" /> -->
            <p class="mt-5"><span class="font-weight-bold">Email:</span> {{ this.authUser.email }}</p>
            <p v-if="plan"><span class="font-weight-bold">Plan:</span> ${{ this.plan }} / month</p>
            <p v-else>You're not subscribed to a plan. If you'd like to create your own feedback boards, view our plans below:</p>
            <v-btn v-if="!plan" :loading="loading" :disabled="loading" color="accent" rounded class="black--text" @click="pricing"
              >View Plans</v-btn
            >
            <v-btn v-if="plan" :loading="loading" :disabled="loading" color="accent" rounded class="black--text" @click="openCustomerPortal"
              >Manage Billing Account</v-btn
            >
          </v-container>
        </div>
        <div v-else>Not logged in</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import Workspace from "../components/Workspace.vue";
import BoardGrid from "../components/BoardGrid.vue";

const db = firebase.firestore();
const functionLocation = "us-east1";

export default {
  components: {
    Workspace,
    BoardGrid,
  },
  data: () => ({
    authUser: null,
    loading: false,
    selectedItem: 0,
    plan: null,
  }),
  mounted: function () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.authUser = user;
        this.getPlan();
      } else {
        this.authUser = null;
      }
    });
  },
  computed: {
    hasGoogleImage() {
      return this.authUser?.providerData[0]?.photoURL ? true : false;
    },
    provider() {
      return this.authUser?.providerData[0]?.providerId ? "Google" : null;
    },
  },
  methods: {
    openCustomerPortal: async function () {
      this.loading = true;
      const functionRef = firebase.app().functions(functionLocation).httpsCallable("ext-firestore-stripe-payments-createPortalLink");
      const { data } = await functionRef({ returnUrl: window.location.origin });
      window.location.assign(data.url);
    },
    getPlan() {
      db.collection("customers")
        .doc(this.authUser.uid)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .onSnapshot(async (snapshot) => {
          if (snapshot.empty) {
            // Show products
          } else {
            const subscription = snapshot.docs[0].data();
            const priceData = (await subscription.price.get()).data();
            this.plan = priceData.unit_amount / 100;
          }
        });
    },
    pricing() {
      this.$router.push("/pricing");
    },
  },
};
</script>

<style></style>
