<template>
  <v-container>
    <h1 class="mb-5">Workspace</h1>
    <div v-if="!loading">
      <v-alert border="bottom" colored-border :type="alertType" elevation="2" v-if="!domain" class="mb-10">
        {{ alertMessage }}
      </v-alert>
      <v-text-field
        color="accent"
        outlined
        label="Workspace Name"
        v-model="workspace"
        style="max-width: 300px"
        :disabled="hasDomain"
        @input="workspace = workspace.toLowerCase()"
      ></v-text-field>
      <div v-if="workspace"><span class="font-weight-bold">URL preview:</span> app.loopliz.com/{{ workspace }}</div>
      <br />
      <v-btn v-if="!hasDomain" :loading="saving" color="accent" rounded class="black--text mt-5" @click="save">Save</v-btn>
    </div>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { domainFromUser } from "../services/auth";
import { createDomain } from "../services/database";
export default {
  data: () => ({
    workspace: "",
    authUser: null,
    domain: null,
    hasDomain: true,
    loading: true,
    saving: false,
    alertMessage: "The workspace name must be set before any boards can be created",
    alertType: "warning",
  }),
  mounted: function () {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.authUser = user;
        this.domain = await domainFromUser(this.authUser.uid);
        if (this.domain) {
          this.workspace = this.domain.name;
        } else {
          this.hasDomain = false;
        }
      } else {
        this.authUser = null;
      }
      this.loading = false;
    });
  },
  methods: {
    save: async function () {
      this.saving = true;
      const created = await createDomain(this.authUser.uid, this.workspace.trim().toLowerCase());
      if (created) {
        this.alertMessage = "Workspace name saved. You can now create a board.";
        this.alertType = "success";
        this.hasDomain = true;
      } else {
        this.alertMessage = "Workspace name could not be saved. Please try another name.";
        this.alertType = "error";
      }
      this.saving = false;
    },
  },
};
</script>

<style></style>
