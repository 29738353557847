<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" width="50%">
    <v-sheet outlined color="accent" rounded>
      <v-card class="pa-2">
        <v-container>
          <v-card-title> <h3>Add new board</h3></v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form">
              <v-text-field color="accent" outlined label="Name" dense :rules="titleRules" v-model="title"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn rounded color="secondary" @click="cancel"> Cancel </v-btn>
            <v-btn rounded color="accent" class="black--text" @click="confirm"> Add board </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "AddRequestDialog",
  data: () => ({
    title: "",
    description: "",
    valid: false,
    titleRules: [(v) => !!v || "Name is required"],
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      if (this.$refs.form.validate()) {
        this.$emit("add-board", this.title);
      }
    },
  },
};
</script>
