<template>
  <v-container>
    <h1>Redirecting to checkout...</h1>
    <v-progress-circular :size="70" color="accent" indeterminate class="mt-8"></v-progress-circular>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { loadStripe } from "@stripe/stripe-js";

const STRIPE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_KEY;
const db = firebase.firestore();
let currentUser;

export default {
  mounted: function () {
    console.log(this.$route.params.price);
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        currentUser = firebaseUser.uid;
        this.checkout();
      } else {
        //
      }
    });
  },
  computed: {
    priceId() {
      console.log(this.$route.params.price);
      switch (this.$route.params.price) {
        case 12:
          return process.env.VUE_APP_SINGLE;
        case 29:
          return process.env.VUE_APP_MULTI;
        case 99:
          return process.env.VUE_APP_UNLIMITED;
        default:
          return process.env.VUE_APP_SINGLE;
      }
    },
  },
  methods: {
    async checkout() {
      const docRef = await db
        .collection("customers")
        .doc(currentUser)
        .collection("checkout_sessions")
        .add({
          price: this.priceId,
          allow_promotion_codes: true,
          success_url: window.location.origin + "/account",
          cancel_url: window.location.origin + "/pricing",
          trial_from_plan: true,
        });

      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(async (snap) => {
        const { sessionId } = snap.data();
        if (sessionId) {
          const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
          stripe.redirectToCheckout({ sessionId });
        }
      });
    },
  },
};
</script>

<style></style>
