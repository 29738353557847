<template>
  <div>
    <v-card class="mx-auto rounded-xl" color="#1d262b" dark elevation="0">
      <v-card-title class="pb-0 pl-0 pt-2">
        <span class="text-h4 font-weight-bold">{{ cardData.name }}</span>
      </v-card-title>

      <v-card-actions class="pb-0 pt-0">
        <v-list-item class="grow">
          <v-row align="center" justify="start">
            <span class="text-h5">{{ voteCount }}</span>
            <v-btn icon @click.stop="toggleIsUpvoted()">
              <v-icon v-if="isUpvoted" color="accent">mdi-arrow-up-bold</v-icon>
              <v-icon v-else>mdi-arrow-up-bold-outline</v-icon>
            </v-btn>
            <v-chip outlined class="ma-2 black--text" v-if="cardData.status === 'Not Started'" color="grey lighten-2">
              {{ cardData.status }}</v-chip
            >
            <v-chip outlined class="ma-2" v-if="cardData.status === 'In Progress'" color="orange lighten-3"> {{ cardData.status }} </v-chip>
            <v-chip outlined class="ma-2" v-if="cardData.status === 'Done'" color="green lighten-3"> {{ cardData.status }}</v-chip>
            <v-chip outlined class="ma-2" v-if="cardData.status === 'Planned'" color="cyan lighten-3"> {{ cardData.status }}</v-chip>
            <v-chip outlined class="ma-2" v-if="cardData.status === 'Closed'" color="red lighten-3"> {{ cardData.status }}</v-chip>
          </v-row>

          <v-spacer></v-spacer>
        </v-list-item>
      </v-card-actions>

      <div class="ql-snow">
        <div class="ql-editor" style="min-height: 50px" v-html="cardData.description"></div>
      </div>
      <vue-editor style="display: none" />

      <div v-if="cardData.images">
        <v-img
          class="mr-2"
          v-for="image in cardData.images"
          :key="image"
          :src="image"
          max-width="200px"
          max-height="200px"
          style="display: inline-block; cursor: pointer"
          @click="selectedItem = image"
        ></v-img>
      </div>

      <v-overlay v-if="selectedItem" @click="selectedItem = null">
        <v-img :src="selectedItem"></v-img>
      </v-overlay>

      <v-card-actions class="pb-0 pt-0">
        <v-list-item-avatar>
          <avatar :username="cardData.author"></avatar>
        </v-list-item-avatar>
        <v-list-item class="pl-0">
          <span class="text--secondary">{{ cardData.author }}</span>
        </v-list-item>
        <v-list-item class="pl-0" v-if="cardData.date">
          <span class="text--secondary">Posted {{ cardData.date.toDate().toLocaleDateString() }}</span>
        </v-list-item>
        <v-list-item class="pl-0" v-if="isRequestOwner">
          <v-btn text color="grey" class="text-none" @click="deleteRequest">Delete</v-btn>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import firebase from "firebase";
import { sum, values } from "lodash";
import { upvoteRequest, deleteRequest } from "../services/database";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    Avatar,
    VueEditor,
  },
  props: ["initialCardData", "boardId"],
  data: () => ({
    isUpvoted: false,
    authUser: null,
    voteCount: 0,
    votes: null,
    selectedItem: null,
    cardData: {
      id: null,
      name: "",
      votes: 0,
      status: "",
      description: "",
      avatar: "",
      author: "Joe Schmoe",
      date: null,
      voters: [],
    },
  }),
  methods: {
    async toggleIsUpvoted() {
      if (!this.authUser) {
        this.$router.push({ name: "Login" });
      }
      this.isUpvoted = !this.isUpvoted;
      if (this.isUpvoted) {
        await upvoteRequest(this.cardData.id, this.authUser.uid, 1);
        this.voteCount++;
      } else {
        await upvoteRequest(this.cardData.id, this.authUser.uid, 0);
        this.voteCount--;
      }
    },
    async deleteRequest() {
      await deleteRequest(this.cardData.id);
      this.$router.push({
        path: "/" + this.$route.params.name + "/board/" + this.$route.params.board,
      });
    },
  },
  watch: {
    initialCardData: function (newVal) {
      this.cardData = newVal;
    },
  },
  computed: {
    isRequestOwner() {
      return this.authUser && this.authUser.uid === this.cardData.author_id;
    },
  },
  mounted: function () {
    this.cardData = this.initialCardData;
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.authUser = user;
      } else {
        this.authUser = null;
      }
      this.isUpvoted = this.authUser ? this.cardData.voters[this.authUser.uid] : false;
      this.voteCount = sum(values(this.cardData.voters));
    });
  },
};
</script>

<style></style>
