import Vue from "vue";
import VueRouter from "vue-router";
import Feed from "../components/Feed.vue";
import Request from "../components/Request.vue";
import Login from "../components/Login.vue";
import Account from "../components/Account.vue";
import Pricing from "../components/Pricing.vue";
import SignUp from "../components/SignUp.vue";
import Checkout from "../components/Checkout.vue";
import DomainHomePage from "../components/DomainHomePage.vue";
import Changelog from "../components/Changelog.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Feed",
    component: Feed,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
  },
  {
    path: "/signup",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/:name",
    name: "DomainHomePage",
    component: DomainHomePage,
  },
  {
    path: "/:name/changelog",
    name: "Changelog",
    component: Changelog,
  },
  {
    path: "/:name/board",
    name: "Board",
    component: Feed,
  },
  {
    path: "/:name/board/:board",
    name: "Board",
    component: Feed,
  },
  {
    path: "/:name/board/:board/request/:title",
    name: "Request",
    component: Request,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
