import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueGtag from "vue-gtag";
import Vue2Editor from "vue2-editor";

Vue.config.productionTip = false;

Vue.use(Vue2Editor);

Vue.use(
  VueGtag,
  {
    config: {
      id: "UA-142798131-12",
      params: {
        send_page_view: true,
      },
    },
    pageTrackerScreenviewEnabled: true,
    appName: "Loopliz",
  },
  router
);

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
