<template>
  <v-card class="mx-auto rounded-xl" color="darkblue" dark max-height="200" elevation="0">
    <v-card-title class="pb-0 pl-0 pt-2">
      <avatar size="30" :username="comment.author"></avatar>
      <span class="text-h6 font-weight-bold ml-2">{{ comment.author }}</span>
    </v-card-title>

    <v-card-text class="text-body-1 text--primary pb-0 pl-0 pt-2">
      {{ comment.content }}
    </v-card-text>
    <v-card-text class="text-body2 pb-0 pl-0 pt-2">
      {{ comment.date.toDate().toLocaleDateString() }}
    </v-card-text>
  </v-card>
</template>

<script>
import Avatar from "vue-avatar";
export default {
  components: {
    Avatar,
  },
  props: ["comment"],
  data: () => ({}),
};
</script>

<style></style>
