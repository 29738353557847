<template>
  <v-container>
    <v-row class="my-5">
      <h1 class="mr-5">{{ displayName }}</h1>
      <v-img v-if="logoUrl" height="50" max-width="50" :src="logoUrl" style="align: center"></v-img>
    </v-row>
    <v-row>
      <v-btn text color="accent" dark @click="goToRoadmap"><v-icon left> mdi-arrow-left </v-icon> View Roadmap </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="accent" dark @click="goToChangelog"> View Changelog <v-icon right> mdi-arrow-right </v-icon> </v-btn>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="3">
        <BoardList :initialBoards="boards" :activeBoard="activeBoard.name" @board-selected="boardSelected" />
        <div class="mt-4 text-center">
          <a href="https://loopliz.com?utm_source=poweredby" target="_blank" style="color: grey">Powered by Loopliz</a>
        </div>
      </v-col>
      <v-col cols="12" md="7" v-if="!loading">
        <FeedNavigationCard
          class="mb-5"
          @add-post="addPost"
          @change-order="orderChanged"
          @change-status="statusChanged"
          @change-search="searchChanged"
        />
        <FeatureCard v-for="request in searchRequests" v-bind:key="request.id" :initialCardData="request" :boardId="activeBoard.id" />
        <p v-if="requests.length === 0">This board has no posts yet!</p>
      </v-col>
      <v-col cols="12" md="7" v-else> Loading... </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import FeatureCard from "./FeatureCard";
import FeedNavigationCard from "./FeedNavigationCard";
import BoardList from "./BoardList";
import { getRequests, addRequest, getDomain, getComments } from "../services/database";
import { orderBy, sum, values, shuffle } from "lodash";

const db = firebase.firestore();
let storageRef = firebase.storage().ref();

export default {
  name: "Feed",
  components: {
    FeatureCard,
    FeedNavigationCard,
    BoardList,
  },
  data: () => ({
    boards: [],
    loading: true,
    requests: [],
    activeBoard: { name: "", id: "" },
    firstBoard: undefined,
    sortOrder: "",
    filterStatus: [],
    searchText: "",
    authUser: null,
    displayName: "",
    logoUrl: "",
    cards: [],
    comments: [],
  }),
  methods: {
    loadBoards() {
      db.collection("boards")
        .where("domain", "==", this.$route.params.name)
        .get()
        .then((querySnapshot) => {
          if (!this.firstBoard) {
            this.firstBoard = querySnapshot.docs[0].data().name;
          }
          querySnapshot.forEach(async (doc) => {
            let board = doc.data();
            board.id = doc.id;
            this.boards.push(board);
            if (board.name === this.firstBoard) {
              this.activeBoard.name = board.name;
              this.activeBoard.id = doc.id;
              await this.loadComments();
              this.loadRequests();
            }
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    async getDomainName() {
      let domain = await getDomain(this.$route.params.name);
      this.displayName = domain.displayname;
      this.logoUrl = domain.logoUrl;
    },
    async loadRequests() {
      this.requests = await getRequests(this.$route.params.name, this.firstBoard);
      for (let i = 0; i < this.requests.length; i++) {
        this.requests[i].comments = this.comments.filter((comment) => comment.request_id === this.requests[i].id);
      }
      this.loading = false;
      this.setVoteCounts();
      this.sortRequests();
    },
    boardSelected(index) {
      let targetBoard = this.boards[index].name;
      this.$router.push({
        path: "/" + this.$route.params.name + "/board/" + targetBoard,
        props: { name: this.$route.params.name, board: targetBoard },
      });
    },
    orderChanged(order) {
      this.sortOrder = order;
      this.sortRequests();
    },
    setVoteCounts() {
      this.requests.forEach((request) => {
        request.voteCount = sum(values(request.voters));
      });
    },
    statusChanged(status) {
      this.filterStatus = status;
    },
    searchChanged(searchText) {
      this.searchText = searchText;
    },
    sortRequests() {
      switch (this.sortOrder) {
        case "Most Votes":
          this.requests = orderBy(JSON.parse(JSON.stringify(this.requests)), ["voteCount"], ["desc"]);
          break;
        case "Least Votes":
          this.requests = orderBy(JSON.parse(JSON.stringify(this.requests)), ["voteCount"], ["asc"]);
          break;
        case "Newest":
          this.requests = orderBy(JSON.parse(JSON.stringify(this.requests)), (item) => item.date.seconds, ["desc"]);
          break;
        case "Oldest":
          this.requests = orderBy(JSON.parse(JSON.stringify(this.requests)), (item) => item.date.seconds, ["asc"]);
          break;
        default:
          this.requests = shuffle(this.requests);
          break;
      }
    },
    async loadComments() {
      this.comments = await getComments(this.$route.params.name, this.activeBoard.name);
    },
    async addPost(post) {
      this.loading = true;
      let images = [];
      if (post.images) {
        for (let i = 0; i < post.images.length; i++) {
          let image = post.images[i];
          let imageRef = storageRef.child("images/" + "/" + this.authUser.uid + "/" + image.name);
          await imageRef.put(image);
          let imageUrl = await imageRef.getDownloadURL();
          images.push(imageUrl);
        }
      }
      const request = {
        name: post.title,
        description: post.description,
        votes: 1,
        status: "Not Started",
        date: new firebase.firestore.Timestamp.now(),
        author: this.authUser.displayName,
        author_id: this.authUser.uid,
        voters: { [this.authUser.uid]: 1 },
        board: this.activeBoard.name,
        domain: this.$route.params.name,
        images: images,
      };
      const response = await addRequest(request);
      if (response && !response.error) {
        this.$router.push({
          path: "/" + this.$route.params.name + "/board/" + this.activeBoard.name + "/request/" + post.title,
        });
      } else {
        this.loading = false;
        console.log("Error adding post");
        console.log(response);
      }
    },
    goToRoadmap() {
      this.$router.push({
        path: "/" + this.$route.params.name,
      });
    },
    goToChangelog() {
      this.$router.push({
        path: "/" + this.$route.params.name + "/changelog",
      });
    },
  },
  computed: {
    filteredRequests() {
      if (this.filterStatus.length) {
        return this.requests.filter((request) => this.filterStatus.includes(request.status));
      } else {
        return this.requests;
      }
    },
    searchRequests() {
      if (this.searchText) {
        return this.filteredRequests.filter(
          (request) =>
            request.description.toLowerCase().includes(this.searchText.toLowerCase()) ||
            request.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else {
        return this.filteredRequests;
      }
    },
  },
  mounted: function () {
    this.firstBoard = this.$route.params.board;
    this.getDomainName();
    this.loadBoards();
    this.loadComments();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.authUser = user;
      } else {
        this.authUser = null;
      }
    });
  },
};
</script>
