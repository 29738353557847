<template>
  <div>
    <v-container>
      <v-row justify="start">
        <v-text-field
          outlined
          label="Search"
          prepend-inner-icon="mdi-magnify"
          dense
          color="accent"
          style="max-width: 300px"
          class="mr-2"
          v-model="searchText"
          @keyup="changedSearch"
        ></v-text-field>
        <v-select
          :items="sortOrders"
          v-model="order"
          color="accent"
          item-color="accent"
          label="Sort"
          outlined
          dense
          style="max-width: 200px"
          class="mr-2"
          @change="changedOrder"
        ></v-select>
        <v-select
          :items="statuses"
          v-model="status"
          color="accent"
          item-color="accent"
          label="Filter"
          outlined
          dense
          multiple
          style="max-width: 200px"
          @change="changedStatus"
        ></v-select>
      </v-row>
      <v-row>
        <v-btn rounded color="accent" class="black--text" dark @click="openAddRequestDialog"> Add new post + </v-btn>
      </v-row>
      <add-request-dialog v-model="addRequestDialog" @add-post="addPost" @cancel="cancel"></add-request-dialog>
    </v-container>
  </div>
</template>

<script>
import AddRequestDialog from "./AddRequestDialog.vue";
import firebase from "firebase";
export default {
  components: {
    AddRequestDialog,
  },
  data: () => ({
    order: "",
    status: null,
    authUser: null,
    searchText: "",
    sortOrders: ["Newest", "Oldest", "Most Votes", "Least Votes"],
    statuses: ["Not Started", "In Progress", "Done", "Planned", "Closed"],
    addRequestDialog: false,
  }),
  methods: {
    addPost(post) {
      this.addRequestDialog = false;
      this.$emit("add-post", post);
    },
    openAddRequestDialog() {
      if (!this.authUser) {
        this.$router.push({ name: "Login" });
      }
      this.addRequestDialog = true;
    },
    changedOrder() {
      this.$emit("change-order", this.order);
    },
    changedStatus() {
      this.$emit("change-status", this.status);
    },
    changedSearch() {
      this.$emit("change-search", this.searchText);
    },
    cancel() {
      this.addRequestDialog = false;
    },
  },
  mounted: function () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.authUser = user;
      } else {
        this.authUser = null;
      }
    });
  },
};
</script>

<style></style>
