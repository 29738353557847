import firebase from "firebase";
const db = firebase.firestore();

export async function createDomain(userId, name) {
  return db
    .collection("domains")
    .doc(name)
    .set({
      name: name,
      admins: [userId],
      owner: userId,
    })
    .then(() => {
      console.log("Domain created");
      return true;
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
      return false;
    });
}

export async function getDomain(name) {
  const domain = await db.collection("domains").doc(name).get();
  return domain.data();
}

export async function createBoard(userId, name, domain) {
  return db
    .collection("boards")
    .doc()
    .set({
      name: name,
      requests: [],
      owner_id: userId,
      domain: domain,
    })
    .then(() => {
      console.log("Board created");
      return true;
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
      return false;
    });
}

export async function getRequestsPerDomain(domain) {
  let requests = [];
  return db
    .collection("requests")
    .where("domain", "==", domain)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((request) => {
        let newRequest = request.data();
        newRequest.id = request.id;
        requests.push(newRequest);
      });
      return requests;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export async function getRequests(domain, board) {
  let requests = [];
  return db
    .collection("requests")
    .where("domain", "==", domain)
    .where("board", "==", board)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((request) => {
        let newRequest = request.data();
        newRequest.id = request.id;
        requests.push(newRequest);
      });
      return requests;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export async function getRequestsCount(domain, board) {
  let requests = [];
  return db
    .collection("requests")
    .where("domain", "==", domain)
    .where("board", "==", board)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((request) => {
        let newRequest = request.data();
        newRequest.id = request.id;
        requests.push(newRequest);
      });
      return requests.length;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export async function getRequest(domain, board, name) {
  return db
    .collection("requests")
    .where("domain", "==", domain)
    .where("board", "==", board)
    .where("name", "==", name)
    .get()
    .then((querySnapshot) => {
      let newRequest = querySnapshot.docs[0].data();
      newRequest.id = querySnapshot.docs[0].id;
      return newRequest;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

export async function addRequest(request) {
  return db.collection("requests").add(request);
}

export async function updateRequest(requestId, updateObject) {
  return db.collection("requests").doc(requestId).update(updateObject);
}

export async function deleteRequest(requestId) {
  return db.collection("requests").doc(requestId).delete();
}

export async function upvoteRequest(requestId, uid, count) {
  return db
    .collection("requests")
    .doc(requestId)
    .update({
      [`voters.${uid}`]: count,
    });
}

export async function addChangelog(changelog) {
  return db.collection("changelogs").add(changelog);
}

export async function getChangelogsPerDomain(domain) {
  let changelogs = [];
  return db
    .collection("changelogs")
    .where("domain", "==", domain)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((request) => {
        let newRequest = request.data();
        newRequest.id = request.id;
        changelogs.push(newRequest);
      });
      return changelogs;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export async function addVote(requestId, userId) {
  let data = {};
  data[userId] = 1;
  return db
    .collection("votes")
    .doc(requestId)
    .set(data)
    .then(() => {
      console.log("Vote count set");
      return true;
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
      return false;
    });
}

export async function addComment(comment) {
  return db
    .collection("comments")
    .add(comment)
    .then(() => {
      console.log("Comment added");
      return true;
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
      return false;
    });
}

export async function getComments(domain) {
  let comments = [];
  return db
    .collection("comments")
    .where("domain", "==", domain)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((commentRef) => {
        let comment = commentRef.data();
        comments.push(comment);
      });
      return comments;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
