<template>
  <v-card elevation="0" rounded="20" outlined>
    <v-list color="darkblue">
      <v-subheader>Boards</v-subheader>
      <v-list-item-group v-model="selectedBoard" color="accent" mandatory>
        <v-list-item v-for="(board, i) in boards" :key="i" :value="board.name" @click="selectBoard(i)">
          <v-list-item-content>
            <v-list-item-title v-text="board.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: ["initialBoards", "activeBoard"],
  data: () => ({
    boards: [],
    selectedBoard: "",
  }),
  watch: {
    initialBoards: function (newVal) {
      this.boards = newVal;
    },
    activeBoard: function (newVal) {
      this.selectedBoard = newVal;
    },
  },
  methods: {
    selectBoard(index) {
      this.$emit("board-selected", index);
    },
  },
  computed: {
    computedBoard: function () {
      return this.selectedBoard;
    },
  },
};
</script>

<style></style>
