<template>
  <v-container>
    <h1 class="mb-5">Boards</h1>
    <div v-if="!loading">
      <v-alert border="bottom" colored-border type="warning" elevation="2" v-if="!domain" class="mb-10">
        The workspace name must be set before any boards can be created
      </v-alert>
      <v-btn v-if="domain" color="accent" rounded class="black--text mb-5" @click="openDialog">Add board</v-btn>
      <v-data-table v-if="domain" color :headers="headers" :items="boards" :items-per-page="10" class="elevation-1">
        <template v-slot:[`item.link`]="{ item }">
          <a target="_blank" :href="item.link">
            <v-icon>mdi-open-in-new</v-icon>
          </a>
        </template>
      </v-data-table>
    </div>
    <add-board-dialog v-model="addBoardDialog" @add-board="addBoard" @cancel="cancel"></add-board-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { domainFromUser } from "../services/auth";
import { createBoard, getRequestsCount } from "../services/database";
import AddBoardDialog from "../components/AddBoardDialog.vue";

const db = firebase.firestore();

export default {
  components: {
    AddBoardDialog,
  },
  data: () => ({
    authUser: null,
    domain: null,
    boards: [],
    loading: true,
    addBoardDialog: false,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Posts",
        value: "postCount",
      },
      {
        text: "Link",
        value: "link",
      },
    ],
  }),
  methods: {
    async getDomain() {
      this.domain = await domainFromUser(this.authUser.uid);
      this.loadBoards();
    },
    loadBoards() {
      this.boards = [];
      db.collection("boards")
        .where("domain", "==", this.domain.name)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            let board = doc.data();
            board.id = doc.id;
            board.link = "/" + this.domain.name + "/board/" + board.name;
            board.postCount = await getRequestsCount(this.domain.name, board.name);
            this.boards.push(board);
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    openDialog() {
      this.addBoardDialog = true;
    },
    cancel() {
      this.addBoardDialog = false;
    },
    async addBoard(name) {
      this.addBoardDialog = false;
      const created = await createBoard(this.authUser.uid, name, this.domain.name);
      if (created) {
        this.loadBoards();
      } else {
        alert("Error creating board");
      }
    },
  },
  mounted: function () {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.authUser = user;
        this.getDomain();
      } else {
        this.authUser = null;
      }
    });
  },
};
</script>

<style></style>
