<template>
  <v-container> Create an account </v-container>
</template>

<script>
import firebase from "firebase";

export default {
  data: () => ({
    loggedIn: false,
  }),
  mounted: function () {
    const googleAuth = new firebase.auth.GoogleAuthProvider();
    if (!this.loggedIn) {
      firebase.auth().signInWithRedirect(googleAuth);
      firebase
        .auth()
        .getRedirectResult()
        .then(() => {
          this.$router.push({ name: "Checkout", params: { price: this.$route.params.price } });
        })
        .catch((error) => {
          console.log(error);
        });
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      });
    }
  },
  computed: {},
};
</script>

<style></style>
