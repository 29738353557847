<template>
  <v-app style="background-color: #1d262b">
    <v-app-bar app color="blue-grey darken4" dark>
      <div style="height: 100%" class="d-flex align-center" @click="home()">
        <img style="height: 80%" src="./assets/logo-no-background.png" />
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="login" color="accent" class="text-none mr-4" outlined v-if="!loggedIn">Login</v-btn>
      <v-btn @click="logout" color="accent" class="text-none mr-4" outlined v-if="loggedIn" :disabled="loading">Logout</v-btn>
      <v-btn @click="account" color="accent" class="text-none" outlined v-if="loggedIn" :disabled="loading"
        >Account<v-icon right dark> mdi-account </v-icon></v-btn
      >
    </v-app-bar>

    <v-main>
      <router-view :key="$route.path" />
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDmSDR9A9sVVHGX8N3yzpJ6ou9Ji2WAktg",
  authDomain: "feedback-app-26908.firebaseapp.com",
  projectId: "feedback-app-26908",
  storageBucket: "feedback-app-26908.appspot.com",
  messagingSenderId: "981711025520",
  appId: "1:981711025520:web:41ae2418d852ed237ecbb5",
  measurementId: "G-S0HN175XB0",
};
firebase.initializeApp(firebaseConfig);

export default {
  name: "App",

  components: {},

  data: () => ({
    session: null,
    loading: false,
    loggedIn: false,
  }),
  mounted: function () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
  },
  methods: {
    home() {
      window.open("https://loopliz.com", "_blank");
    },
    login() {
      this.$router.push({ name: "Login" });
    },
    account() {
      this.$router.push({ name: "Account" });
    },
    async logout() {
      console.log("logout");
      firebase
        .auth()
        .signOut()
        .then(
          function () {
            // Sign-out successful.
          },
          function (error) {
            console.log(error);
          }
        );
    },
  },
  computed: {},
};
</script>
