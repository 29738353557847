import Vue from "vue";
import { colors } from "vuetify/lib";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        danger: colors.red.darken3,
        accent: colors.amber.accent1,
        darkblue: "#1d262b",
      },
      light: {
        custom: colors.red,
      },
    },
  },
});
