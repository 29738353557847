<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" :width="isMobile ? '90%' : '50%'">
    <v-sheet outlined color="accent" rounded>
      <v-card class="pa-2">
        <v-container>
          <v-card-title> <h3>Add new changelog</h3></v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form">
              <v-text-field color="accent" outlined label="Title" dense :rules="titleRules" v-model="title"></v-text-field>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div style="max-width: 50%;">
          <v-text-field
            v-model="date"
            label="Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            color="accent"
            max-width="50%"
          ></v-text-field>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="accent"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="accent"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
              <VueEditor
                v-model="description"
                :editor-toolbar="customToolbar"
                placeholder="Description"
                :rules="descriptionRules"
              />
              <p v-if="errors.length" style="color: #ff5252;" class="mt-2">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </p>
              <v-select :items="requests" chips color="accent" label="Included items" class="mt-5" item-color="accent" multiple item-text="name" v-model="includedRequests" />
              <div style="max-width: 50%;">
              <v-file-input
                label="Attach images"
                v-model="images"
                dense
                prepend-icon="mdi-camera"
                small-chips
                accept="image/png, image/jpeg"
                color="accent"
                multiple
                :rules="imageRules"
                class="mt-8"
              ></v-file-input>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn rounded color="secondary" @click="cancel"> Cancel </v-btn>
            <v-btn rounded color="accent" class="black--text" @click="confirm"> Save </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-sheet>
  </v-dialog>
</template>

<style>
.ql-editor.ql-blank::before {
  color: rgb(255, 255, 255, 0.5) !important;
  font-family: "Nunito" !important;
  font-style: normal !important;
}

.quillWrapper .ql-snow.ql-toolbar {
  border-radius: 5px 5px 0px 0px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0px 0px 5px 5px;
  font-family: "Nunito" !important;
}
</style>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "AddChangelogDialog",
  components: {
    VueEditor,
  },
  props: ["requests"],
  data: () => ({
    title: "",
    description: "",
    date: new Date().toISOString().split("T")[0],
    menu: "",
    includedRequests: [],
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["code-block", "blockquote"],
    ],
    valid: false,
    images: [],
    errors: [],
    titleRules: [
      (v) => !!v || "Title is required",
      (v) => !v.includes("/") || "Title cannot contain '/'",
      (v) => !v.includes("?") || "Title cannot contain '?'",
      (v) => (v && v.length >= 10) || "Title must be at least 10 characters",
    ],
    imageRules: [
      (v) => (v && v.length <= 5) || "You can upload up to 5 images",
    ],
    descriptionRules: [
      (v) => !!v || "Description is required",
      (v) =>
        (v && v.length >= 20) || "Description must be at least 20 characters",
    ],
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.checkForm();
      if (this.$refs.form.validate() && this.errors.length === 0) {
        this.$emit("add-post", {
          title: this.title,
          date: this.date,
          description: this.description,
          requests: this.includedRequests,
          images: this.images,
        });
        this.title = "";
        this.description = "";
        this.images = [];
      }
    },
    checkForm() {
      this.errors = [];
      if (!this.description) {
        this.errors.push("Description is required.");
      }
      if (this.description?.length < 20) {
        this.errors.push("Description must be at least 20 characters.");
      }
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.width < 768;
    },
  },
};
</script>
